.tblMatrix thead th {
    height:70px;
    padding:0;
    text-align:left;
}
.tblMatrix thead th div {
    transform: rotate(-90deg);
    font-weight:bold;
}
.tblMatrix thead th span {
    display:inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:50px;
}

.MatrixTbl .sticky-table {
    white-space: nowrap;
    border:2px solid #e5e5e5;
}
.MatrixTbl .sticky-table .sticky-table-cell {
    padding: .5rem .75rem;
    border-bottom: 2px solid #e5e5e5;
    background-color: transparent;
    text-align:center;
    position:relative;
}
.MatrixTbl .sticky-table .sticky-table-column, 
.MatrixTbl .sticky-table .sticky-table-corner {
    border-right: 2px solid #e5e5e5;
}
.MatrixTbl .sticky-table-header .sticky-table-cell,
.MatrixTbl .sticky-table-header .sticky-table-cell * {
    box-sizing:border-box;
}
.MatrixTbl .sticky-table-header .sticky-table-cell {
    /* height:110px !important;
    max-height:110px; */
    /* height:100%; */
    padding:0;
    text-align:center;
    /* min-width:50px !important;
    width:50px !important;
    max-width:50px !important; */
    background:#fff;
}
.MatrixTbl .sticky-table-corner .sticky-table-cell {
    /* height:110px !important; */
    /* height:100%; */
    padding:0;
}
.MatrixTbl .sticky-table-header .sticky-table-cell div {
    /* height:100%; */
    transform: rotate(180deg);
    transform-origin: 50% 50%;
    display:block;
    height:130px;
}
.MatrixTbl .sticky-table-header .sticky-table-cell span {
    display:block;
    margin:0 auto;
    /* width:100%;
    height:100%; */
    white-space: nowrap;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    max-height:120px;
    padding-top:5px;

    writing-mode: vertical-rl;
}
.MatrixTbl .sticky-table .sticky-table-header-wrapper .sticky-table-row {
    /* max-height:140px;
    height:140px !important; */
}
.BaseTable__header-cell-text.text-smaller {
    transform: rotate(-90deg);
    overflow: visible;
    white-space: nowrap;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: flex-start;
    margin-bottom: -80px;
}
.BaseTable__header-cell-text.text-smaller span {
    display:inline-block;
    
}
.MatrixTbl .BaseTable__row-cell {
    padding:0;
}
.MatrixTbl .text-smaller {
    font-size:90%;
}
.MatrixTbl .BaseTable__row-cell-text {
    width:100%;
    height:100%;
    padding:0 7.5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.MatrixTbl .sticky-table-cell.no-data,
.MatrixTbl .BaseTable__row-cell-text.no-data {
    background:#cdcdcd;
}
.MatrixTbl .sticky-table-cell.with-data,
.MatrixTbl .BaseTable__row-cell-text.with-data {
    cursor: pointer;
}
.MatrixTbl .sticky-table-x-wrapper .sticky-table-row:hover {
    background:#eee;
}
.MatrixTbl .sticky-table-cell.with-data:hover,
.MatrixTbl .BaseTable__row-cell-text.with-data:hover {
    background:#ffffcd;
}
.MatrixTbl .sticky-table-cell.with-data:hover::after {
    content: "";
    position: absolute;
    background-color: #eee;
    left: 0;
    top: -5000px;
    height: 10000px;
    width: 100%;
    z-index: -1;
}
.MatrixTbl .sticky-table .sticky-table-x-wrapper {
    overflow-y: hidden; /* hide the pseudo element overflow above */ 
}

.MatrixTbl .sticky-table-cell.with-data.highlighted,
.MatrixTbl .BaseTable__row-cell-text.with-data.highlighted {
    -webkit-animation: backgroundin 3s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: backgroundin 3s; /* Firefox < 16 */
        -ms-animation: backgroundin 3s; /* Internet Explorer */
         -o-animation: backgroundin 3s; /* Opera < 12.1 */
            animation: backgroundin 3s;
}

.MatrixTbl .sticky-table-header,
.MatrixTbl .sticky-table-column {
    position:relative;
    overflow:visible;
}
.MatrixTbl .sticky-table-header:after {
    content:'';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    pointer-events: none;
    box-shadow: inset 0px 11px 8px -10px rgba(0,0,0,0.15);
}
.MatrixTbl .sticky-table-column:after {
    content:'';
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    z-index: 99;
    pointer-events: none;
    box-shadow: inset 11px 0px 8px -8px rgba(0,0,0,0.15);
}

@keyframes backgroundin {
    0% { background:#3cbd5e; }
    70% { background:#3cbd5e; }
    100% { background:none; }
}
/* Firefox < 16 */
@-moz-keyframes backgroundin {
    0% { background:#3cbd5e; }
    70% { background:#3cbd5e; }
    100% { background:none; }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes backgroundin {
    0% { background:#3cbd5e; }
    70% { background:#3cbd5e; }
    100% { background:none; }
}
/* Internet Explorer */
@-ms-keyframes backgroundin {
    0% { background:#3cbd5e; }
    70% { background:#3cbd5e; }
    100% { background:none; }
}
/* Opera < 12.1 */
@-o-keyframes backgroundin {
    0% { background:#3cbd5e; }
    70% { background:#3cbd5e; }
    100% { background:none; }
}