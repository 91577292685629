form.customform {
    margin-bottom:24px;
}
form.customform .field {
    position: relative;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-bottom:15px;
}
form.customform .field > label {
    position: absolute;
    top: 1px;
    left: 10px;
    padding: 6px 3px 3px;
    opacity: 0;
    visibility: hidden;
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    color: #9e9d9d;
    border-top-left-radius: 3px;
    transition: all 0.2s ease-in-out;
    z-index: 1;
}
form.customform input,
form.customform select,
form.customform textarea,
form.customform button {
    padding:12px;
    color:#222;
    width: 100%;
    outline: 0;
    font-size: 15px;
    line-height: 1.5;
    border-radius: 3px;
    border: 2px solid #dfdfdf;
    background-color: #fff;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    font-family:'Open Sans', sans-serif;
}
form.customform input[type=date], /* remove the dropdown style in safari; if set to none then height is not maintained */
form.customform input[type=month], 
form.customform input[type=time], 
form.customform input[type=text], /* removes the inner shadow in safari */
form.customform input[type=email], 
form.customform input[type=password] {
    -webkit-appearance: none;
    -moz-appearance: none;
}
form.customform input[type=date],
form.customform input[type=month],
form.customform input[type=time] {
    height:52px;
}
form.customform input[type=date][value='']::-webkit-datetime-edit,
form.customform input[type=time][value='']::-webkit-datetime-edit {
    color: transparent; 
}
form.customform input[type=date][value='']:focus::-webkit-datetime-edit,
form.customform input[type=time][value='']:focus::-webkit-datetime-edit {
    color: black !important;
}
form.customform input[type=date][value='']:after,
form.customform input[type=time][value='']:after {
    color: #000;
    content: attr(placeholder);
    position:absolute;
    left:12px;
}
form.customform .noappearance select {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 1.25em;
    appearance: none;
    position: relative;
    background-color: transparent;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10px' width='15px'%3E%3Ctext x='0' y='10' fill='gray'%3E%E2%96%BE%3C/text%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 1.2em 0.8em;
    background-position: right center;
    background-clip: border-box;
    -moz-background-clip: border-box;
    -webkit-background-clip: border-box;
}
/*  
need to generalise this to non-contactform
need to separate each input/select into its own component and track active state
active - has content
focus - focused
*/
form.customform .active > label {
    opacity: 1;
    visibility: visible;
}
form.customform .active input,
form.customform .active select,
form.customform .active textarea {
    padding: 18px 12px 6px;
}
form.customform .focused > label {
    color:#a14740;
}
form.customform .focused > input,
form.customform .focused > select,
form.customform .focused > textarea {
    border-color:#a14740;
}

form.customform input[type=submit],
form.customform button,
form.customform .button {
    display: inline-block;
    background: #a03f3f;
    border-radius: 3px;
    padding: 10px 20px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    vertical-align: top;
    color: #fff;
    width:auto;
    border:0;
    text-transform: uppercase;
    line-height:1.5;
    margin-bottom:10px;
}
form.customform button.warn,
form.customform .button.warn {
    background:#e01717;
}
form.customform input[type=submit]:hover,
form.customform button:hover,
form.customform .button:hover {
    background: #c25e57;
}
form.customform button.positive,
form.customform .button.positive {
    background:#3cbd5e;
}
form.customform input.positive[type=submit]:hover,
form.customform button.positive:hover,
form.customform .button.positive:hover {
    background: #3fa059;
}
form.customform button.info,
form.customform .button.info {
    background:#3c94bd;
}
form.customform input.info[type=submit]:hover,
form.customform button.info:hover,
form.customform .button.info:hover {
    background: #3f66a0;
}
form.customform input[type=submit][disabled],
form.customform input[type=submit][disabled]:hover,
form.customform button[disabled],
form.customform button[disabled]:hover,
form.customform .button.disabled,
form.customform .button.disabled:hover {
    background:#a0a0a0;
    cursor:not-allowed;
}

form.customform .has-error input,
form.customform .has-error textarea,
form.customform .has-error select {
    background:#ffcdcd;
}
form.customform .help-block {
    font-size:80%;
    color:red;
}
form.customform .has-error .help-block {
    padding-left:10px;
}
form.customform input:focus,
form.customform input:visited {
    outline:none;
}
form.customform input[disabled],
form.customform textarea[disabled],
form.customform select[disabled] {
    background:#efefef;
}
form.customform .showpasswordinput button {
    color: #222;
    position: absolute;
    background: #efefef;
    top: 2px;
    right: 2px;
    height: calc(100% - 4px);
    max-height: 46px;
    border-radius: 0 3px 3px 0;
}
form.customform center,
form.customform hr {
    margin-bottom:15px;
}
form.customform .footnote {
    color:#656565;
    margin-bottom:5px;
    font-size:0.8em;
}