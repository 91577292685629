.modal-bg {
    position:fixed;
    background:rgba(0,0,0,0.5);
    width:100%;
    height:100%;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index:99;
}
.modal-main {
    background:#fff;
    position:absolute;
    z-index:100;
    width:80%;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    max-width:600px;
    padding:20px;
    border-radius:3px;

    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}
.modal.display-block {
    display:block;
}
.modal.display-none {
    display:none;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}